.login-comp {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .bg-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -99;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media all and (max-width: 760px) {
      -webkit-filter: brightness(90%); /* Safari 6.0 - 9.0 */
      filter: brightness(90%);
    }
  }

  // cirsoalr area

  .cir-area {
    h1 {
      font-family: "Montserrat", sans-serif;
      color: #fff;
      font-size: 2.8vw;
    }

    @media screen and (max-width: 1028px) {
      h1 {
        font-size: 8vw;
      }
    }
  }

  // QR Code area
  .qr-code-area {
    width: 188px;
    height: 188px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .notice-message-area {
    color: #fff;
    margin-top: 20px;
  }
}
